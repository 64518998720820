import React from 'react'
import PropTypes from 'prop-types'
import { Drawer as MuiDrawer } from '@material-ui/core'

const Drawer = ({ open, children, onClose, anchor = 'right' }) => (
  <MuiDrawer anchor={anchor} open={open} onClose={onClose}>
    {children}
  </MuiDrawer>
)

Drawer.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.object,
  onClose: PropTypes.func,
  anchor: PropTypes.string,
}

export default Drawer
