import React from 'react'
import RTGLink from '@shared/link'
import rtgTo from '@helpers/rtg-to'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

const FilterLink = ({ item, attribute, children, applyStateChange, toggleFilter, getUrl }) => {
  const isMobile = useSelector(state => state.global.isMobile)
  const link = getUrl(attribute, item.label)
  return (
    <RTGLink
      aria-checked={item.isRefined}
      role="checkbox"
      data={{
        rel: 'nofollow',
        target: '_blank',
        [rtgTo(link)]: link,
        category: 'plp parts',
        action: 'filter link click',
        label: 'filter link',
      }}
      onKey={e => {
        if (e.keyCode !== 32) return
        // spacebar press
        if (e.keyCode === 32) {
          e.preventDefault()
          e.target.click()
        }
      }}
      onClick={e => {
        e.preventDefault()
        applyStateChange(attribute, item.label)
        if (!isMobile) toggleFilter()
      }}
    >
      {children}
    </RTGLink>
  )
}

FilterLink.propTypes = {
  applyStateChange: PropTypes.func,
  attribute: PropTypes.any,
  children: PropTypes.any,
  getUrl: PropTypes.func,
  item: PropTypes.any,
  toggleFilter: PropTypes.func,
}

export default FilterLink
