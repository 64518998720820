import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import ClearSvg from '@components/shared/svgs/clearSvg'
import { breakPoints, colors } from '@constants/styles'
import SearchFilters from './search-filters'
import SearchSorting from './search-sorting'
import Stats from './plp-parts/Stats'

const Summary = styled(Grid)`
  &&& {
    padding: 0;
    margin-bottom: 20px;
    display: none;
    @media only screen and (min-width: ${breakPoints['large-min']}) {
      display: flex;
    }
  }
`

const SClearSvg = styled(ClearSvg)`
  cursor: pointer;
  circle {
    fill: #0053a0;
  }
`

const SearchMobileTitle = styled.span`
  height: 24px;
  width: 108px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
`

const MobileHeaderWrapper = styled(Grid)`
  &&& {
    margin-bottom: 24px;
  }
`

const ClearAll = styled.a`
  color: ${colors.primary};
  text-decoration: underline;
`

const Sidebar = ({
  searching,
  searchState,
  applyStateChange,
  refinementList,
  matchPath,
  refinedSearchResults,
  getUrl,
  stats,
  clearState,
  setOpenModalSidebar,
  openModalSidebar,
}) => {
  const isMobile = useSelector(state => state.global.isMobile)

  const header = (
    <MobileHeaderWrapper container justify="space-between" alignItems="center">
      <button
        type="button"
        title="Clear the search query."
        hidden=""
        onClick={() => setOpenModalSidebar(false)}
        tabIndex={0}
      >
        <SClearSvg />
      </button>
      <SearchMobileTitle>Sort &amp; Filter</SearchMobileTitle>
      {JSON.stringify(searchState.filters) !== '{}' && (
        <ClearAll
          href={typeof window !== 'undefined' ? window.location.pathname : ''}
          rel="nofollow"
          onClick={e => {
            e.preventDefault()
            clearState()
          }}
        >
          Clear All
        </ClearAll>
      )}
    </MobileHeaderWrapper>
  )

  const body = (
    <>
      <Summary container justify="space-between" alignItems="center">
        <Stats
          nbHits={refinedSearchResults?.content?.hits?.length && refinedSearchResults?.content?.nbHits}
          searching={searching}
        />
        {JSON.stringify(searchState.filters) !== '{}' && (
          <ClearAll
            href={typeof window !== 'undefined' ? window.location.pathname : ''}
            rel="nofollow"
            onClick={e => {
              e.preventDefault()
              clearState()
            }}
          >
            Clear All
          </ClearAll>
        )}
      </Summary>
      <SearchSorting sortBy={searchState?.sortBy} applyStateChange={applyStateChange} />
      <SearchFilters
        attributes={refinementList}
        matchPath={matchPath}
        facets={refinedSearchResults?.content?.facets}
        applyStateChange={applyStateChange}
        getUrl={getUrl}
        statsComponent={stats}
      />
    </>
  )

  if (isMobile && openModalSidebar) {
    return (
      <>
        {header}
        {body}
      </>
    )
  }

  if (isMobile && !openModalSidebar) {
    return null
  }

  return body
}

Sidebar.propTypes = {
  searching: PropTypes.any,
  searchState: PropTypes.any,
  applyStateChange: PropTypes.any,
  refinementList: PropTypes.any,
  matchPath: PropTypes.any,
  refinedSearchResults: PropTypes.any,
  getUrl: PropTypes.any,
  stats: PropTypes.any,
  clearState: PropTypes.any,
  setOpenModalSidebar: PropTypes.func,
  openModalSidebar: PropTypes.bool,
}

export default Sidebar
