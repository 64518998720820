import PropTypes from 'prop-types'
import React from 'react'
import '../../../assets/css/components/search/connectors/stats.sass'
import { announce } from '@helpers/aria-announce'
import styled from 'styled-components'
import { isEqual } from 'lodash'
import { breakPoints } from '@constants/styles'

const StatsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;

  @media screen and (min-width: ${breakPoints['large-min']}) {
    position: absolute;
  }
`

export class Stats extends React.Component {
  componentDidMount() {
    const { nbHits } = this.props
    announce(`${nbHits.toLocaleString()} RESULTS`)
  }

  shouldComponentUpdate(nextProps) {
    const { searching: nextSearching, nbHits: nextNBHits } = nextProps
    const { searching, nbHits } = this.props
    return !isEqual(searching, nextSearching) || !isEqual(nbHits, nextNBHits)
  }

  render() {
    const { nbHits, searching } = this.props

    const resultsText = nbHits > 0 ? `${nbHits.toLocaleString()} Results` : `Sorry, no results found.`
    return <span id="searchResultsCount">{searching ? <div>Loading...</div> : <p>{resultsText}</p>}</span>
  }
}

Stats.propTypes = {
  nbHits: PropTypes.number,
  searching: PropTypes.any,
}

export default Stats
