import React from 'react'
import PropTypes from 'prop-types'

const ClearSvg = ({ className }) => (
  <svg className={className} width="21px" height="21px" viewBox="0 0 21 21" version="1.1">
    <g id="Search---Large-Viewport" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Search---Leather-Sofas-|-selected-filters"
        transform="translate(-812.000000, -33.000000)"
        fillRule="nonzero"
      >
        <g id="Close_Icons" transform="translate(812.000000, 33.000000)">
          <circle id="Ellipse_9" fill="#AAAAAF" cx="10.5" cy="10.5" r="10.5" />
          <path
            d="M11.9848235,10.5877059 L16.7444118,5.86517647 C16.9811843,5.63038993 17.074714,5.28712159 16.9897691,4.96467731 C16.9048243,4.64223302 16.65431,4.38959975 16.3325927,4.30194201 C16.0108753,4.21428427 15.6668313,4.30491934 15.4300588,4.53970588 L10.6605882,9.27335294 L5.89111765,4.54094118 C5.65434513,4.30615464 5.31030118,4.21551956 4.98858381,4.30317731 C4.66686645,4.39083505 4.41635219,4.64346832 4.33140734,4.9659126 C4.24646249,5.28835688 4.33999219,5.63162523 4.57676471,5.86641176 L9.33635294,10.5889412 L4.57676471,15.3114706 C4.34021285,15.5462571 4.24688706,15.8894077 4.33194224,16.2116609 C4.41699742,16.5339141 4.66751168,16.7863119 4.98911871,16.8737786 C5.31072574,16.9612452 5.6545658,16.8704924 5.89111765,16.6357059 L10.6605882,11.9032941 L15.4300588,16.6357059 C15.7957363,16.9986544 16.3864044,16.9964422 16.7493529,16.6307647 C17.1123015,16.2650872 17.1100892,15.6744191 16.7444118,15.3114706 L11.9848235,10.5877059 Z"
            id="Path_63"
            fill="#FFFFFF"
          />
        </g>
      </g>
    </g>
  </svg>
)

ClearSvg.propTypes = {
  className: PropTypes.string,
}

export default ClearSvg
