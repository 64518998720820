import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { breakPoints } from '@constants/styles'
import { titleCase } from '@helpers/string-helper'
import useOutsideClick from '@helpers/hooks/useOutsideClick'
import Accordion, { summaryWithCounter } from './Accordion'
import FilterLink from './FilterLink'

const FilterOuterWrapper = styled.div`
  flex-grow: 1;
  position: relative;
  white-space: nowrap;
  :last-child {
    .MuiAccordionSummary-root {
      margin: 0;
    }
    .MuiAccordionSummary-root.Mui-expanded {
      margin: 0 0 10px 0;
    }
    .MuiAccordion-root.Mui-expanded {
      margin: 0;
    }
    .MuiCollapse-wrapperInner {
      > div > div > div a:last-child > div {
        margin: 0;
      }
    }
  }

  @media screen and (min-width: ${breakPoints['large-min']}) {
    & > :not(:first-child) {
      display: none;
    }
  }
`

const FilterDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledAccordion = styled(Accordion)`
  @media screen and (max-width: ${breakPoints['large-max']}) {
  }
`

const FilterItemWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 12px 0;

  &&& div.checkbox {
    background-color: ${({ isRefined }) => (isRefined ? '#0053a0' : '#fff')};
  }

  @media screen and (max-width: ${breakPoints.small}) {
    margin: 0.75rem 0;
  }
`

const FilterItemText = styled.span`
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
  color: black;
`

const Checkbox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  vertical-align: sub;
  border: 1px solid #585860;
  width: 26px;
  height: 26px;
  margin-right: 9.5px;

  &:hover {
    border: 0.1rem solid #0053a0;
  }
`

const ColorBox = styled.div`
  width: 26px;
  height: 26px;
  margin-right: 5.5px;
  border: 1px solid #000;
  background-color: ${({ itemLabel }) => itemLabel};
  border-radius: 5px;
`

const RefinementList = ({ items, attribute, applyStateChange, getUrl }) => {
  const [amount, setAmount] = useState(0)
  const [isActive, setIsActive] = useState(false)
  const wrapperRef = useRef(null)
  useOutsideClick(wrapperRef, () => setIsActive(false))

  useEffect(() => {
    const newAmount = items.reduce((prev, next) => (next?.isRefined ? prev + 1 : prev), 0)
    setAmount(newAmount)
  }, [items])

  const toggleFilter = forcedIsActive => {
    if (typeof forcedIsActive !== 'undefined') {
      setIsActive(forcedIsActive)
    }
    setIsActive(!isActive)
  }

  // TODO wut this do
  const splitters = [' ', ',', '-']
  const attributeLabel =
    !attribute.includes('size') && attribute.indexOf('_family') !== -1
      ? attribute.substring(0, attribute.indexOf('_family'))
      : titleCase(attribute.split('_').join(' '))

  if (items.length > 0) {
    return (
      <FilterOuterWrapper ref={wrapperRef}>
        <StyledAccordion summary={summaryWithCounter(titleCase(attributeLabel, splitters), amount)}>
          <FilterDetailsWrapper>
            {items.map(
              item =>
                !item.label.includes(',') && (
                  <FilterLink
                    item={item}
                    attribute={attribute}
                    key={item.label}
                    applyStateChange={applyStateChange}
                    toggleFilter={toggleFilter}
                    getUrl={getUrl}
                  >
                    {typeof window !== 'undefined' && (
                      <FilterItemWrapper isRefined={item.isRefined}>
                        <Checkbox className="checkbox">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 0 24 24"
                            width="24px"
                            fill="#fff"
                          >
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                          </svg>
                        </Checkbox>

                        {attributeLabel === 'color' && (
                          <ColorBox attributeLabel={attributeLabel} itemLabel={item.label} />
                        )}
                        <FilterItemText>{titleCase(item.label, splitters).replace(/,/g, ', ')}</FilterItemText>
                      </FilterItemWrapper>
                    )}
                  </FilterLink>
                ),
            )}
          </FilterDetailsWrapper>
        </StyledAccordion>
      </FilterOuterWrapper>
    )
  }
  return null
}

RefinementList.propTypes = {
  applyStateChange: PropTypes.any,
  attribute: PropTypes.any,
  getUrl: PropTypes.any,
  items: PropTypes.any,
}

export default RefinementList
