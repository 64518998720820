import React from 'react'
import { saveLocalStorage } from '@helpers/storage'
import { number, func } from 'prop-types'
import '@assets/css/components/product/product-pagination.sass'
import classNames from 'classnames'

export class Pagination extends React.Component {
  updatePage(newPage) {
    const { currentPage, applyStateChange } = this.props
    const page = newPage ?? currentPage
    saveLocalStorage('rtg_scroll', {})
    applyStateChange('page', page)
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }
    try {
      document.getElementById('productResultsWrapper').focus()
    } catch (error) {}
  }

  render() {
    const { nbPages, padding, currentPage } = this.props
    const start = currentPage - padding > 0 ? currentPage - padding : 1
    const end = currentPage + padding + 1 < nbPages ? currentPage + padding + 1 : nbPages
    const pageRange = Array.from({ length: end + 1 - start }, (a, b) => b + start)
    const isCurrent = page => (currentPage === page ? true : null)
    const pageOptions = pageRange.map(page => (
      <li key={page}>
        <button
          role="link"
          type="button"
          tabIndex="0"
          onClick={() => this.updatePage(page)}
          className={classNames({ 'active-page': isCurrent(page) || !isCurrent(page) === 1 })}
          aria-current={isCurrent(page)}
        >
          <span className="hide508">Page</span>
          {page}
        </button>
      </li>
    ))

    if (nbPages <= 1) {
      return null
    }

    return (
      <div className="plp-pagination">
        <nav role="navigation" aria-label="Pagination">
          {currentPage > 1 && (
            <button
              type="button"
              className="arrow"
              aria-label="Next Page"
              onClick={() => this.updatePage(currentPage - 1)}
            >
              <span aria-hidden="true">‹</span>
            </button>
          )}
          <ul>{pageOptions}</ul>
          {currentPage < nbPages && (
            <button
              type="button"
              role="link"
              tabIndex="0"
              className="arrow"
              aria-label="Next Page"
              onClick={() => this.updatePage(currentPage + 1)}
            >
              <span aria-hidden="true">›</span>
            </button>
          )}
        </nav>
      </div>
    )
  }
}

Pagination.propTypes = {
  nbPages: number,
  padding: number,
  currentPage: number,
  applyStateChange: func,
}

export default Pagination
