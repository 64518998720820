const rtgTo = path => {
  if (!path || path.startsWith('http://') || path.startsWith('https://')) {
    return 'url'
  }
  return 'slug'
}

export const dataWithTo = (data = {}) => {
  let path = ''

  if (data?.url) {
    path = data.url
  } else if (data?.internalUrl?.url) {
    path = data.internalUrl.url
  } else {
    path = data?.slug
  }

  const to = rtgTo(path)
  const result = data ? { ...data } : {}
  delete result.url
  delete result.slug
  result[to] = path
  return result
}

export default rtgTo
