import qs from 'querystring'
import { isEmpty } from 'lodash'

const normalize = url => (url.endsWith('/') ? url.substring(0, url.length - 1) : url)

const urlToSearchState = ({ search }, displayFilters) => {
  const plpState = { filters: {} }
  if (search) {
    const urlState = qs.parse(search.slice(1))
    Object.keys(urlState).forEach(key => {
      const isFilter = displayFilters.includes(key)
      if (isFilter) {
        if (!Array.isArray(urlState[key])) {
          plpState.filters[key] = [urlState[key]]
        } else {
          plpState.filters[key] = urlState[key]
        }
      }
      if (key === 'page') {
        plpState.page = Number.parseInt(urlState[key]) || 0
      } else if (key === 'sortBy') {
        plpState.sortBy = urlState.sortBy
      }
    })
  }
  return plpState
}

export const stateToUrl = (state, location) => {
  let url = normalize(location.pathname)
  url += !isEmpty(state) ? `?${qs.stringify(state)}` : ''
  delete state.page
  if (!isEmpty(state)) {
    url += '&filters=true'
  }
  return url
}

export const initSearchState = (searchResults, location, displayFilters) => {
  const state = urlToSearchState(location, displayFilters)
  Object.keys(state.filters).forEach(key => {
    if (isEmpty(state.filters[key])) {
      delete state.filters[key]
    }
  })

  state.page = state.page ?? 1
  state.sortBy = state.sortBy ?? searchResults.content.index
  return state
}

export const windowPush = url => {
  const normalizedUrl = normalize(url)
  window.history.replaceState(null, document.title, normalizedUrl)
}
