import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled from 'styled-components'
import '@assets/css/components/search/search-sorting.sass'
import { breakPoints } from '@constants/styles'
import { getRegionZone } from '@helpers/geo-location'
import { addToDataLayer } from '@helpers/google-tag-manager'
import Accordion, { textSummary } from './plp-parts/Accordion'
import RadioButtonsGroup from './plp-parts/RadioButtonsGroup'

const SortByWrapper = styled.div`
  display: flex;
  align-items: center;

  @media screen and (min-width: ${breakPoints['large-max']}) {
    > div {
      width: 100%;
    }
  }
`

export default class SearchSorting extends Component {
  state = {
    searchIndex: null,
    searchZone: null,
  }

  componentDidMount() {
    this.setState({ searchIndex: this.searchIndex(), searchZone: this.searchZone() })
  }

  searchIndex() {
    return `${getRegionZone().region}-${process.env.GATSBY_ENV_SHORT}`
  }

  searchZone() {
    const { zone } = getRegionZone()
    return zone === 0 ? '' : `-zone${zone}`
  }

  render() {
    const { searchIndex, searchZone } = this.state
    const { applyStateChange, sortBy } = this.props

    return (
      <SortByWrapper>
        <Accordion summary={textSummary('Sort')}>
          {searchIndex && typeof window !== 'undefined' && (
            <RadioButtonsGroup
              onChange={applyStateChange}
              callback={value => addToDataLayer('click', 'plp', 'sort change', value)}
              value={sortBy}
              items={[
                {
                  value: searchIndex,
                  label: 'Our Recommendations',
                },
                {
                  value: `${searchIndex}${searchZone}-price-desc`,
                  label: 'Price (High to Low)',
                },
                {
                  value: `${searchIndex}${searchZone}-price-asc`,
                  label: 'Price (Low to High)',
                },
                {
                  value: `${searchIndex}-availability`,
                  label: 'Availability',
                },
              ]}
            />
          )}
        </Accordion>
      </SortByWrapper>
    )
  }
}

SearchSorting.propTypes = {
  applyStateChange: PropTypes.any,
  sortBy: PropTypes.any,
}
