const algoliasearch = require('algoliasearch')
const algoliasearchHelper = require('algoliasearch-helper')
const { isEmpty } = require('lodash')

const getFilterString = (originalFilters, facetFilters) => {
  if (isEmpty(facetFilters)) {
    return originalFilters
  }
  const additionalFilters = Object.keys(facetFilters)
    .map(
      facet =>
        `(${facetFilters[facet]
          .map(filter => `"${facet}":"${filter.replace(/\\/g, '\\\\').replace(/"/g, '\\"')}"`)
          .join(' OR ')})`,
    )
    .join(' AND ')
  return [originalFilters, additionalFilters].filter(Boolean).join(' AND ')
}

exports.refine = ({ oldState, filters, page = 1, index = process.env.GATSBY_ALGOLIA_REGION_INDEX }) => {
  const client = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_API_KEY)
  const searchSettings = {
    query: oldState?.query,
    facets: oldState?.facets,
    hitsPerPage: oldState?.hitsPerPage || 20,
    // TODO: removing this line should fix sorting, but it will break fetching
    ...(isEmpty(filters) && { ruleContexts: oldState?.ruleContexts }),
    // enableRules: false,
    filters: getFilterString(oldState?.filters, filters),
    page: page - 1,
  }
  return algoliasearchHelper(client, index, searchSettings).searchOnce()
}
