import PropTypes from 'prop-types'
import styled from 'styled-components'
import React from 'react'
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@material-ui/core'
import { colors, theme } from '@constants/styles'

const StyledRadioGroup = styled(RadioGroup)`
  > label {
    margin: 0 0 12px 0;
    > span:first-child {
      padding: 0;
      margin-right: 10px;
      color: ${colors.darkerGrey};
    }

    > span:last-child {
      font-size: 15px;
      letter-spacing: 0;
      line-height: 26px;
      color: ${colors.black};
    }

    > span > span > div {
      > svg {
        fill: inherit;
        stroke: ${colors.white};
        :nth-child(2) {
          stroke-width: 4px;
          stroke: ${theme.primary};
          fill: ${theme.primary};
        }
      }
    }
  }
`

export default function RadioButtonsGroup({ className, label, onChange, value, items, callback }) {
  const handleChange = e => {
    onChange('sortBy', e.target.value)
    if (callback) {
      callback(e.target.value)
    }
  }

  return (
    <FormControl className={className} component="fieldset">
      {label && <FormLabel>{label}</FormLabel>}
      <StyledRadioGroup value={value} onChange={handleChange}>
        {items?.map(item => (
          <FormControlLabel
            key={item.label}
            value={item.value}
            control={<Radio color="primary" />}
            label={item.label}
          />
        ))}
      </StyledRadioGroup>
    </FormControl>
  )
}

RadioButtonsGroup.propTypes = {
  className: PropTypes.any,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  items: PropTypes.array,
  callback: PropTypes.func,
}
