import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Accordion, AccordionSummary, Typography } from '@material-ui/core'
import { colors, fonts } from '@constants/styles'
import ExpandSvg from '@components/shared/svgs/expandSvg'
import CircleCounter from '@components/shared/circle-counter'

const StyledAccordion = styled(Accordion)`
  background: inherit;
  border: none;
  box-shadow: none;
  outline: none;
  width: 100%;
  &.MuiAccordion-root.Mui-expanded {
    margin: 0 0 13px 0;
  }

  .accordion-summary {
    padding: 0;
  }

  .MuiAccordionSummary-root {
    height: min-content;
    min-height: min-content;
    outline: none;
    margin: 0 0 10px 0;
    padding: 0;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    height: min-content;
    min-height: min-content;
    outline: none;
    margin: 0 0 10px 0;
  }

  .MuiAccordionSummary-content {
    margin: 0;

    > p {
      width: 100%;
    }
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
  .MuiAccordionSummary-expandIcon {
    padding: 0;
  }
  .MuiIconButton-edgeEnd {
    margin-right: 0;
  }
`

const ExpandIcon = styled(ExpandSvg)`
  fill: ${colors.primary};
`

const SummaryWrapper = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 24px;
`
const TextSummary = styled(Typography)`
  font-size: ${fonts.txtXmedium};
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25px;
  color: ${colors.black};
  height: 24px;
`

export function summaryWithCounter(title, number) {
  return (
    <SummaryWrapper>
      <TextSummary>{title}</TextSummary>
      {number > 0 && <CircleCounter quantity={number} />}
    </SummaryWrapper>
  )
}

export function textSummary(title) {
  return <TextSummary>{title}</TextSummary>
}
export default function SimpleAccordion({
  className,
  summary,
  children,
  disableIcon,
  customIcon: CustomIcon,
  defaultExpanded = true,
  id,
}) {
  return (
    <StyledAccordion defaultExpanded={defaultExpanded} className={className} id={id}>
      <AccordionSummary
        className="accordion-summary"
        expandIcon={!disableIcon && (CustomIcon ? <CustomIcon /> : <ExpandIcon />)}
      >
        {summary}
      </AccordionSummary>
      <div>{children} </div>
    </StyledAccordion>
  )
}

SimpleAccordion.propTypes = {
  className: PropTypes.any,
  disableIcon: PropTypes.any,
  customIcon: PropTypes.any,
  summary: PropTypes.any,
  children: PropTypes.node,
  defaultExpanded: PropTypes.bool,
  id: PropTypes.string,
}
