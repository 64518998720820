import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { colors, fonts } from '@constants/styles'

const Circle = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  color: ${colors.white};
  background-color: ${colors.primary};
  > span {
    font-size: ${fonts.txtSmall};
    line-height: normal;
  }
`

const CircleCounter = ({ quantity }) => {
  if (typeof quantity === 'undefined') {
    return null
  }

  return (
    <Circle>
      <span>{quantity}</span>
    </Circle>
  )
}

export default CircleCounter

CircleCounter.propTypes = {
  quantity: PropTypes.number,
}
